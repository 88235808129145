<script setup>

import { onMounted, reactive, ref, watch } from 'vue'
import { useFiltersCargueFacturasStore } from '../../../store/filtersCargueFacturas.store'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

const filtersCargueFacturasStore = useFiltersCargueFacturasStore()
const _RecepcionPedidosService = ref(new RecepcionPedidosService())
const listProveedores = ref([])
const filters = reactive({
  tipoFecha: '',
  fechaInicial: '',
  fechaFinal: '',
  NumAtCard: '',
  proveedor: ''
})
const listTipoFecha = ref([
  { id: 'FECHA_CREACION', name: 'Fecha Creación' },
  { id: 'FECHA_FACTURA', name: 'Fecha Factura' }
])

// Validar que la fecha final no sea menor a la fecha inicial
const validateFechaFinal = (fechaInicial, fechaFinal) => {
  const fecIni = dayjs(fechaInicial)
  const fecFin = dayjs(fechaFinal)
  if (fecFin.isBefore(fecIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La fecha final no puede ser menor a la fecha inicial'
    })
    return true
  } else {
    return false
  }
}

// Realizar la búsqueda y setear los filtros en el store
const updateFilter = () => {
  if (validateFechaFinal(filters.fechaInicial, filters.fechaFinal)) {
    return
  }

  const filtersData = {
    tipoFecha: filters.tipoFecha ?? '',
    fechaInicial: filters.fechaInicial ?? '',
    fechaFinal: filters.fechaFinal ?? '',
    NumAtCard: filters.NumAtCard ?? '',
    proveedor: filters.proveedor ?? ''
  }

  filtersCargueFacturasStore.setUpdateFilters(filtersData)
}

// Limpiar los filtros
const resetClear = () => {
  for (const [key] of Object.entries(filtersCargueFacturasStore.getFilters)) {
    filters[key] = ''
  }
  filtersCargueFacturasStore.setUpdateFilters(filters)
}

const reload = () => {
  filtersCargueFacturasStore.setRefrescarListadoCargueFacturas(true)
}

// Obtener los proveedores
const fetchProveedores = async () => {
  await _RecepcionPedidosService.value.listarProveedores().then((res) => {
    listProveedores.value = res.data
    // Agregar el item de todos
    listProveedores.value.unshift({ CardCode: '', CardName: 'Todos' })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Si se selecciona el tipo de fecha se habilita el filtro de fecha inicial con el primer día del mes actual y el filtro de fecha final con el último día del mes actual
watch(() => filters.tipoFecha, (value) => {
  if (value) {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    filters.fechaInicial = firstDay.toISOString().slice(0, 10)
    filters.fechaFinal = lastDay.toISOString().slice(0, 10)
  } else {
    filters.fechaInicial = ''
    filters.fechaFinal = ''
  }
})

// Hook onMounted
onMounted(async () => {
  await fetchProveedores()

  // Setear los filtros del store al filters reactive
  for (const [key, value] of Object.entries(filtersCargueFacturasStore.getFilters)) {
    filters[key] = value
  }
})

</script>

<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-2">

    <div class="col-span-12 sm:col-span-2">
      <label for="tipoFecha" class="block text-xs font-medium text-gray-700 mb-2">Tipo fecha:</label>
      <Dropdown
        id="tipoFecha"
        v-model="filters.tipoFecha"
        :options="listTipoFecha"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.tipoFecha ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>

    <div class="col-span-12 sm:col-span-2">
      <label for="fechaInicial" class="block text-xs font-medium text-gray-700 mb-2">Fecha Inicial:</label>
      <InputText
        id="fechaInicial"
        type="date"
        class="w-full text-sm"
        :disabled="!filters.tipoFecha"
        v-model="filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.fechaInicial ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaFinal" class="block text-xs font-medium text-gray-700 mb-2">Fecha Final:</label>
      <InputText
        id="fechaFinal"
        type="date"
        class="w-full text-sm"
        :disabled="!filters.tipoFecha"
        v-model="filters.fechaFinal"
        :min="filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.fechaFinal ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>

    <div class="col-span-12 sm:col-span-2">
      <label for="NumAtCard" class="block text-xs font-medium text-gray-700 mb-2">Número Factura:</label>
      <InputText
        id="NumAtCard"
        type="text"
        class="w-full text-sm"
        placeholder="Buscar por número de factura..."
        v-model="filters.NumAtCard"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.NumAtCard ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>

    <div class="col-span-12 sm:col-span-2">
      <label for="proveedor" class="block text-xs font-medium text-gray-700 mb-2">Proveedor:</label>
      <Dropdown
        id="proveedor"
        v-model="filters.proveedor"
        :options="listProveedores"
        optionLabel="CardName"
        optionValue="CardCode"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.proveedor ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>

    <div class="col-span-12 lg:col-span-12">
      <div class="flex flex-wrap justify-end gap-2 w-full">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="updateFilter"
          v-tippy="{ content: 'Buscar' }"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="resetClear"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <Button
          icon="pi pi-refresh text-sm"
          severity="success"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="reload()"
          v-tippy="{ content: 'Recargar' }"
        />
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
