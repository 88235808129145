<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import { useRoute, useRouter } from 'vue-router'
import Filters from '../filters/index.vue'
import { useFiltersCargueFacturasStore } from '../../../store/filtersCargueFacturas.store'
import Icon from '@/components/Icon/index.vue'

const filtersCargueFacturasStore = useFiltersCargueFacturasStore()
const _RecepcionPedidosService = ref(new RecepcionPedidosService())
const listPreliminares = ref([])
const dt = ref()
const route = useRoute()
const router = useRouter()
const limit = ref(20)
const offset = ref(0)
const orderField = ref('CreatedAt')
const sortOrder = ref(-1)
const expandedRows = ref([])
const op = ref()
const infoLotes = ref([])

// Visualizar tabla de lotes
const toggleLotes = (event, data) => {
  infoLotes.value = data.Lotes
  op.value.toggle(event)
}

// Computada para obtener el store de filtros
const getterFiltersCargueFacturas = computed(() => {
  return filtersCargueFacturasStore.getFilters
})

// Función que permite la actualización de parámetros de consulta de manera dinámica antes de realizar la navegación a una nueva URL.
const _routerPush = (obj = {}) => {
  const queryParams = route.query
  router.push({ query: { ...queryParams, ...obj } })
}

// Ordenar por campo
const orderQuery = ({ sortField, sortOrder }) => {
  _routerPush({ order: `${sortField},${sortOrder}` })
}

// Paginación
const onPage = (row) => {
  const queryParams = { ...route.query }
  limit.value = row.rows
  offset.value = row.first

  // _routerPush({ ...queryParams, limit: row.rows, offset: row.first })
  router.push({ query: { ...queryParams, limit: row.rows, offset: row.first } })
}

// Asignacion de los query params
const setQueryParams = () => {
  const queryParams = { ...route.query }
  limit.value = queryParams.limit ? parseInt(queryParams.limit) : 20
  offset.value = queryParams.offset ? parseInt(queryParams.offset) : 0
  queryParams.limit = queryParams.limit ? queryParams.limit : 20
  queryParams.offset = queryParams.offset ? queryParams.offset : 0
  queryParams.order = queryParams.order ? queryParams.order : 'CreatedAt,-1'

  // Split a la cadena para obtener el campo y el orden
  const splitOrder = queryParams.order.split(',')
  orderField.value = splitOrder[0]
  sortOrder.value = parseInt(splitOrder[1])

  return queryParams
}

/* Obtener el listado de preliminares entrada de mercancia */
const fetchListPreliminares = async () => {
  await _RecepcionPedidosService.value.listarPreliminaresEntrada(setQueryParams()).then((res) => {
    console.log('res.data', res.data)
    listPreliminares.value = res.data
  }).catch((error) => {
    console.log(error)
  })
}

// Funcion para retornar el router query de la URL
const getRouteQuery = async () => {
  console.log('getRouteQuery', route.query)
  // Si el objeto no viene vacio
  if (Object.keys(route.query).length !== 0) {
    delete route.query.limit
    delete route.query.offset
    delete route.query.order

    // Parsear los valores de los filtros a enteros
    Object.keys(route.query).forEach((key) => {
      if (['area', 'motivo', 'cargo'].includes(key)) {
        // Si el valor es vacío o string, no se parsea
        if (route.query[key] !== '' && !isNaN(route.query[key])) {
          route.query[key] = parseInt(route.query[key])
        }
      }
    })

    filtersCargueFacturasStore.setUpdateFilters(route.query)
  } else {
    filtersCargueFacturasStore.setUpdateFilters({})
  }
}

// Expandir y contraer filas
const onRowExpand = (event) => {
  console.log('onRowExpand', event)
}
const onRowCollapse = (event) => {
  console.log('onRowCollapse', event)
}

// Funcion para obtener y manejar mejor el estado
const getStatusInfo = (data) => {
  switch (data.Estado) {
    case 1:
      return {
        class: 'bg-yellow-100 text-yellow-800 border-yellow-400',
        text: 'EN PROCESO'
      }
    case 2:
      return {
        class: 'bg-green-100 text-green-800 border-green-400',
        text: 'ENVIADO A SAP'
      }
    case 3:
      return {
        class: 'bg-red-100 text-red-800 border-red-400',
        text: 'FALLÓ'
      }
  }
}

// Convertir ServiceLayerResponse a objeto
const parseServiceLayerResponse = (ServiceLayerResponse) => {
  if (ServiceLayerResponse) {
    return JSON.parse(ServiceLayerResponse)
  } else {
    return {}
  }
}

// Crear contenido del tooltip para mostrar el resultado de la respuesta de SAP
const createTooltipContent = (data) => {
  const parsedData = parseServiceLayerResponse(data.ServiceLayerResponse)

  const statusColor = parsedData.status !== 201 ? 'red-400' : 'green-400'

  return `
    <div class='flex flex-col'>
      <div class='flex flex-wrap justify-between items-center'>
        <span class='mr-2 '>Resultado:</span>
        <span class='text-${statusColor} font-bold'>${parsedData.status !== 201 ? 'Falló' : 'Éxito'}</span>
      </div>
      <div class='flex flex-wrap justify-between items-center'>
        <span class='mr-2'>Mensaje:</span>
        <span class='text-${statusColor} font-bold'>${parsedData.status !== 201 ? parsedData.message : parsedData.statusText}</span>
      </div>
      <div class='flex flex-wrap justify-between items-center'>
        <span class='mr-2'>DocNum:</span>
        <span class='text-${statusColor} font-bold'>${parsedData.DocNum ?? '---'}</span>
      </div>
    </div>
  `
}

// Watch si cambian los filtros
watch(getterFiltersCargueFacturas, async (newFilter) => {
  _routerPush(newFilter)
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async (value) => {
  await fetchListPreliminares()
})

// Watch para refrescar la tabla
watch(() => filtersCargueFacturasStore.getRefrescarListadoCargueFacturas, async (value) => {
  if (value) {
    await fetchListPreliminares()
    filtersCargueFacturasStore.setRefrescarListadoCargueFacturas(false)
  }
})

// Hook onMounted
onMounted(async () => {
  await getRouteQuery()
  await fetchListPreliminares()
})
</script>

<template>

  <div class="box intro-x">
    <div class="mt-4">

      <!-- Titulo Card -->
      <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto capitalize text-base font-medium">Listado</h3>
        <div class="flex">
        </div>
      </div>

      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4 gap-5">

        <!-- Filtros -->
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <Filters />
        </div>

        <!-- Tabla listado -->
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <DataTable
            ref="dt"
            :value="listPreliminares.data"
            v-model:expandedRows="expandedRows"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
            tableStyle="min-width: 50rem"
            class="p-datatable-sm text-xs"
            dataKey="Id"
            @sort="orderQuery"
            :sortField="orderField"
            :sortOrder="sortOrder"
          >
            <template #empty> No hay datos para mostrar. </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column expander style="width: 5rem" />
            <Column field="Id" header="Id" :sortable="true"></Column>
            <Column field="CreatedAt" header="Fecha" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>Factura: {{ $h.formatDate(data.DocDate, 'YYYY-MM-DD') }}</span>
                  <span>Creación: {{ $h.formatDate(data.CreatedAt, 'YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </template>
            </Column>
            <Column field="NumAtCard" header="Num. Factura" :sortable="true"></Column>
            <Column field="DocTotal" header="Total Factura" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ $h.formatCurrency(parseFloat(data.DocTotal)) }}</span>
                </div>
              </template>
            </Column>
            <Column field="CardName" header="Proveedor" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.CardName }}</span>
                  <span class="text-xs text-gray-700">{{ data.CardCode }}</span>
                </div>
              </template>
            </Column>
            <Column field="Comments" header="Comentarios" :sortable="false"></Column>
            <Column field="U_PHR_UserWs" header="Usuario" :sortable="true"></Column>
            <Column field="DocNum" header="DocNum SAP" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.DocNum ?? '---' }}</span>
                </div>
              </template>
            </Column>
            <Column field="Estado" header="Estado" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col flex-wrap justify-center">
                  <button v-if="data.ServiceLayerResponse"
                    class="text-xs font-medium px-2.5 py-0.5 rounded border w-full"
                    :class="getStatusInfo(data).class"
                    v-tippy="{
                      content: createTooltipContent(data),
                      trigger: 'click'
                    }"
                  >
                    {{ getStatusInfo(data).text }}
                  </button>
                  <button v-else
                    class="text-xs font-medium px-2.5 py-0.5 rounded border w-full"
                    :class="getStatusInfo(data).class"
                  >
                    {{ getStatusInfo(data).text }}
                  </button>
                </div>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div v-if="slotProps.data.Detalle.length === 0" class="w-full flex flex-col items-center justify-center">
                <Icon icon="healthicons:not-ok" class="text-5xl text-gray-500"/>
                <span class="text-red-800 opacity-70">No hay detalle</span>
              </div>
              <div class="" v-else>
                <h5 class="text-sm font-semibold text-gray-600 mb-2">Información de los articulos</h5>
                <DataTable
                  :value="slotProps.data.Detalle"
                  showGridlines
                  responsiveLayout="scroll"
                  class="p-datatable-sm text-xs"
                >
                  <Column field="LineNum" header="Linea" />
                  <Column field="ItemCode" header="Cod. Mx" />
                  <Column field="ItemName" header="Nom. Mx" />
                  <Column field="Quantity" header="Cantidad" />
                  <Column field="UnitPrice" header="Precio Unitario">
                    <template #body="{data}">
                      <div class="flex flex-col">
                        <span>{{ $h.formatCurrency(parseFloat(data.UnitPrice)) }}</span>
                      </div>
                    </template>
                  </Column>
                  <Column field="WhsCode" header="Bodega">
                    <template #body="{data}">
                      <div class="flex flex-col">
                        <span>{{ data.WhsCode }}-{{ data.WhsName }}</span>
                      </div>
                    </template>
                  </Column>
                  <Column header="Lotes" :exportable="false">
                    <template #body="{data}">
                      <div class="flex flex-col flex-wrap justify-center">
                        <button
                          class="text-xs font-medium px-2.5 py-0.5 rounded border w-full bg-blue-100 text-blue-800 border-blue-400"
                          @click="toggleLotes($event,data)"
                        >
                          Ver Lotes
                        </button>
                        <OverlayPanel
                          ref="op"
                          :pt="{
                            content: {
                              style: 'padding: 1rem'
                            },
                          }"
                        >
                          <DataTable
                            :value="infoLotes"
                            class="p-datatable-sm text-xs"
                            showGridlines
                            dataKey="Id"
                            responsiveLayout="scroll"
                          >
                            <template #empty> No hay información porque no tiene lotes. </template>
                            <Column field="DistNumber" header="Lote"></Column>
                            <Column field="CantidadLote" header="Cantidad"></Column>
                            <Column field="ExpDate" header="Fecha Vencimiento"></Column>
                          </DataTable>
                        </OverlayPanel>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(listPreliminares.total)"
            :rowsPerPageOptions="[5,10,20,30,100, listPreliminares.total]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
            :pt="{
              rowPerPageDropdown: {
                input: {
                  style: 'padding: 5px'
                },
              },
            }"
          />
        </div>

      </div>

    </div>
  </div>

</template>

<style>
.tippy-content {
  overflow-wrap: break-word;
}
</style>
